<template>
  <footer class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
    <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
      <div v-for="item in navigation" :key="item.name" class="px-5 py-2">
        <a
          class="text-base text-gray-500 hover:text-gray-900 cursor-pointer"
          :href="item.routeName"
          v-smooth-scroll
        >
          {{ item.name }}
        </a>
      </div>
    </nav>
    <p class="mt-8 text-center text-base text-gray-400">
      2020-{{ new Date().getFullYear() }} Domnest &copy; Wszelkie prawa
      zastrzeżone
    </p>
  </footer>
</template>

<script>
import { defineComponent } from "vue";
import { navigation } from "@/components/DHeader.vue";

export default defineComponent({
  setup() {
    return {
      navigation,
    };
  },
});
</script>
