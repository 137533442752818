
import { defineComponent } from "vue";

declare interface ISetup {
  faqs: IFaqItem[];
}

declare interface IFaqItem {
  question: string;
  answer: string;
}

const faqs = [
  {
    question: "Czy inwestowanie w fotowoltaikę się opłaca?",
    answer:
      "Tak. Dzięki realizowanym dodatkowo systemom wsparcia i programom dotacji do zakupu paneli fotowoltaicznych inwestowanie w ogniwa fotowoltaiczne jest wysoce opłacalne.",
  },
  {
    question: "Jaki jest okres gwarancji na instalacje fotowoltaiczne?",
    answer:
      "Okres gwarancji różni się w zależności od komponentu instalacji fotowoltaicznej. Na moduły fotowoltaiczne jest to nawet 25 lat, a w przypadku falowników nawet 10 lat z możliwością przedłużenia gwarancji.",
  },
  {
    question: "Jaką trwałość mają panele fotowoltaiczne?",
    answer:
      "Panele mogą pracować nawet 30 lat, jednak ich żywotność zależy od technologii. Obecnie najbardziej trwałe są ogniwa z krzemu monokrystalicznego, których czas pracy szacuje się na około 25–30 lat, a najmniej trwałe są ogniwa wykonane z krzemu amorficznego (żywotność 8–10 lat).",
  },
  {
    question: "Ile energii wytwarzają panele fotowoltaiczne?",
    answer:
      "W Polsce przykładowa instalacja o mocy 10 kWp generuje średnio w ciągu roku ok. 10 000 kWh. Ilość wytworzonej energii elektrycznej zależy od rodzaju i sprawności paneli, a także od warunków atmosferycznych.",
  },
];

export default defineComponent({
  setup(): ISetup {
    return {
      faqs,
    };
  },
});
