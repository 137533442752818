import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_hero = _resolveComponent("hero")!
  const _component_logos = _resolveComponent("logos")!
  const _component_solutions = _resolveComponent("solutions")!
  const _component_realizations = _resolveComponent("realizations")!
  const _component_faq = _resolveComponent("faq")!
  const _component_contact = _resolveComponent("contact")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_hero),
    _createVNode(_component_logos),
    _createVNode(_component_solutions),
    _createVNode(_component_realizations),
    _createVNode(_component_faq),
    _createVNode(_component_contact)
  ]))
}