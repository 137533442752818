
import { defineComponent } from "vue";
import Hero from "@/pages/Home/Index/Hero.vue";
import Logos from "@/pages/Home/Index/Logos.vue";
import Solutions from "@/pages/Home/Index/Solutions.vue";
import Realizations from "@/pages/Home/Index/Realizations.vue";
import Contact from "@/pages/Home/Index/Contact.vue";
import Faq from "@/pages/Home/Index/Faq.vue";

export default defineComponent({
  components: { Faq, Contact, Realizations, Solutions, Logos, Hero },
});
