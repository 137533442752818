import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "faq",
  class: "py-8"
}
const _hoisted_2 = { class: "max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8" }
const _hoisted_3 = { class: "lg:grid lg:grid-cols-3 lg:gap-8" }
const _hoisted_4 = { class: "text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl" }
const _hoisted_5 = { class: "mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4" }
const _hoisted_6 = { class: "mt-12 lg:mt-0 lg:col-span-2" }
const _hoisted_7 = { class: "space-y-12" }
const _hoisted_8 = { class: "text-lg leading-6 font-medium text-gray-900" }
const _hoisted_9 = { class: "mt-2 text-base text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t("faq.title")), 1),
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("faq.desc")), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("dl", _hoisted_7, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.faqs, (faq) => {
              return (_openBlock(), _createElementBlock("div", {
                key: faq.question
              }, [
                _createElementVNode("dt", _hoisted_8, _toDisplayString(faq.question), 1),
                _createElementVNode("dd", _hoisted_9, _toDisplayString(faq.answer), 1)
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ]))
}