
import { MailIcon, PhoneIcon, XIcon } from "@heroicons/vue/outline";
import { defineComponent } from "vue";
import Http from "@/services/Http";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/vue/solid";

export default defineComponent({
  components: {
    MailIcon,
    PhoneIcon,
    XCircleIcon,
    CheckCircleIcon,
    XIcon,
  },
  computed: {
    buttonClass() {
      if (this.loadingState) {
        return ["opacity-75", "pointer-events-none"];
      }

      return [];
    },
  },
  data() {
    return {
      form: {
        first_name: "",
        email: "",
        content: "",
      },
      success: false,
      loadingState: false,
      errors: [],
      messages: {},
    };
  },
  methods: {
    send() {
      this.loadingState = true;
      Http.getInstance()
        .post("contact/send", this.form)
        .then(() => {
          this.success = true;
          this.errors = [];
          this.messages = {};
          this.form = {
            first_name: "",
            email: "",
            content: "",
          };
        })
        .catch((err) => {
          const data = err.response.data;
          this.success = false;
          this.errors = data.errors;
          this.messages = data.messages;
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
});
