<template>
  <div id="realizations" class="relative py-8">
    <div class="max-w-7xl mx-auto py-8 px-4 sm:py-12 sm:px-6 lg:px-8">
      <div class="absolute inset-0">
        <div class="h-1/3 sm:h-2/3" />
      </div>
      <div class="relative max-w-7xl mx-auto">
        <div class="flex justify-between justify-center">
          <div>
            <h2
              class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl"
            >
              {{ $t("realizations.title") }}
            </h2>
            <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
              {{ $t("realizations.desc") }}
            </p>
          </div>
        </div>
        <div
          class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none"
        >
          <div
            v-for="item in displayItems"
            :key="item.id"
            class="flex flex-col rounded-lg shadow-lg overflow-hidden"
          >
            <div class="flex-shrink-0">
              <img
                class="h-48 w-full object-cover object-center"
                :src="item.photo"
                :alt="item.title"
              />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <p class="text-xl font-semibold text-gray-900">
                {{ item.title }}
              </p>
              <p class="mt-2 text-base text-gray-500">
                {{ item.description }}
              </p>
              <div class="mt-4 grid grid-cols-1">
                <p class="flex items-center text-sm text-gray-500">
                  <location-marker-icon
                    class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>{{ item.localization }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="itemsCounter < items.length"
          class="flex justify-center mt-8"
        >
          <button
            type="button"
            class="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700"
            @click="itemsCounter += 3"
          >
            {{ $t("realizations.show-more") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import {
  LocationMarkerIcon,
} from "@heroicons/vue/outline";
import Http from "@/services/Http";

// declare interface IData {
//   items: IRealization[];
// }
//
// export interface IRealization {
//   id: number;
//   title: string;
//   power: number;
//   number_of_modules: number;
//   localization: string;
//   description: string;
//   photo: string;
// }

export default defineComponent({
  components: { LocationMarkerIcon },
  computed: {
    displayItems() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.items.filter((i, index) => index < this.itemsCounter);
    },
  },
  data() {
    return {
      itemsCounter: 3,
      items: [],
    };
  },
  mounted() {
    Http.getInstance()
      .get("/realizations")
      .then((res) => {
        this.items = res.data.data;
      });
  },
});
</script>
