
import {
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/vue";
import {
  SunIcon,
  MenuIcon,
  XIcon,
  HomeIcon,
  ViewBoardsIcon,
  FireIcon,
  SwitchHorizontalIcon,
  LightBulbIcon,
  PhoneIcon,
} from "@heroicons/vue/outline";
import { Component, defineComponent } from "vue";
import i18n from "@/i18n";

declare interface ISetup {
  solutions: ISolution[];
  navigation: INavigationItem[];
}

declare interface ISolution {
  name: string;
  description: string;
  routeName: string;
  icon: Component;
}

declare interface INavigationItem {
  name: string;
  routeName: string;
}

export const solutions: ISolution[] = [
  {
    name: i18n.global.t("menu.solutions.photovoltaics.title"),
    description: i18n.global.t("menu.solutions.photovoltaics.desc"),
    routeName: "PhotovoltaicsIndex",
    icon: SunIcon,
  },
  {
    name: i18n.global.t("menu.solutions.heating-pump.title"),
    description: i18n.global.t("menu.solutions.heating-pump.desc"),
    routeName: "HeatingPumpIndex",
    icon: HomeIcon,
  },
  {
    name: i18n.global.t("menu.solutions.heating-foils.title"),
    description: i18n.global.t("menu.solutions.heating-foils.desc"),
    routeName: "HeatingFoilsIndex",
    icon: ViewBoardsIcon,
  },
  {
    name: i18n.global.t("menu.solutions.heating-mats.title"),
    description: i18n.global.t("menu.solutions.heating-mats.desc"),
    routeName: "HeatingMatsIndex",
    icon: FireIcon,
  },
  {
    name: i18n.global.t("menu.solutions.air-conditioning.title"),
    description: i18n.global.t("menu.solutions.air-conditioning.desc"),
    routeName: "AirConditioningIndex",
    icon: SwitchHorizontalIcon,
  },
  {
    name: i18n.global.t("menu.solutions.electric-installations.title"),
    description: i18n.global.t("menu.solutions.electric-installations.desc"),
    routeName: "ElectricInstallationsIndex",
    icon: LightBulbIcon,
  },
];

export const navigation: INavigationItem[] = [
  {
    name: i18n.global.t("menu.solutions.title"),
    routeName: "#solutions",
  },
  {
    name: i18n.global.t("menu.realizations"),
    routeName: "#realizations",
  },
  // {
  //   name: i18n.global.t("menu.calculator"),
  //   routeName: "CalculatorIndex",
  // },
  {
    name: i18n.global.t("menu.faq"),
    routeName: "#faq",
  },
  {
    name: i18n.global.t("menu.contact"),
    routeName: "#contact",
  },
];

export default defineComponent({
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    MenuIcon,
    XIcon,
    PhoneIcon,
  },
  setup(): ISetup {
    return {
      solutions,
      navigation,
    };
  },
});
