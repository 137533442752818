import { createI18n } from "vue-i18n";

const messages = {
  pl: {
    interface: {
      "open-menu": "Otwórz menu",
      "close-menu": "Zamknij menu",
      email: "Email",
      "phone-number": "Number telefonu",
      "first-name": "Imię",
      subject: "Temat",
      message: "Wiadomość",
      "send-message": "Wyślij wiadomość",
    },
    menu: {
      solutions: {
        title: "Rozwiązania",
        photovoltaics: {
          title: "Fotowoltaika",
          desc: "Fotowoltaika to darmowe i ekologiczne źródło energii odnawialnej.",
        },
        "heating-pump": {
          title: "Pompy ciepła",
          desc: "Pompa ciepła to urządzenie, które ogrzeje Twój dom zimą, a schłodzi latem.",
        },
        "heating-foils": {
          title: "Folie grzewcze IR",
          desc: "Folia grzewcza to cienki i elastyczny grzejnik elektryczny na podczerwień.",
        },
        "heating-mats": {
          title: "Maty grzewcze",
          desc: "Mata grzewcza przeznaczona jest do ogrzewania podłogowego.",
        },
        "air-conditioning": {
          title: "Klimatyzacje",
          desc: "Klimatyzator sprawdza się znakomicie zarówno podczas chłodzenia wnętrz.",
        },
        "electric-installations": {
          title: "Instalacje elektryczne",
          desc: "Instalacja elektryczna to niezbędny element każdego domu.",
        },
      },
      realizations: "Realizacje",
      calculator: "Kalkulator",
      faq: "FAQ",
      contact: "Skontaktuj się",
    },
    hero: {
      title: "Nowoczesne i ekologiczne",
      desc: "Zachęcamy do zapoznania się z naszymi rozwiązaniami. W przypadku jakichkowiek pytań lub chęci zlecenia wyceny zapraszamy do kontaktu.",
      cta: {
        "see-solutions": "Zobacz rozwiązania",
        contact: "Skontaktuj się",
      },
    },
    logos: {
      title: "Wspołpracujemy z najlepszymi",
    },
    solutions: {
      desc: "Najlepsze rozwiązania dla Twojego domu.",
    },
    realizations: {
      title: "Sprawdź nasze realizacje",
      desc: "Zaufały nam dziesiątki klientów, zainstalowaliśmy setki instalacji.",
      panels: "paneli",
      "show-more": "Pokaż więcej",
    },
    faq: {
      title: "Najczęściej zadawane pytania",
      desc: "Wszystko co chcesz wiedzieć o naszych rozwiązaniach w jednym miejscu.",
    },
    contact: {
      title: "Skontaktuj się za nami",
      desc: "Wypełnij krótki formularz, a my wrócimy do Ciebie z odpowiedzią najszybciej jak to możliwe.",
      "contact-information": "Informacje kontaktowe",
      "contact-desc":
        "Jeśli masz jakiekowiek pytania z przyjemnością na wszystkie odpowiemy.",
      "send-message": "Wyślij do nas wiadomość",
      "how-can-we-help-you": "Jak możemy Ci pomóc?",
      "success-title": "Wysłano pomyślnie!",
      "success-desc":
        "Dziękujemy za wysłanie wiadomości, odpowiemy najszybciej jak to możliwe.",
      loading: "Wysyłanie...",
    },
  },
};

export default createI18n({
  locale: "pl",
  messages,
});
