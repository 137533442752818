
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      texts: [
        "instalacje fotowoltaicze",
        "pompy ciepła",
        "folie grzewcze IR",
        "maty grzewcze",
        "klimatyzacje",
        "instalacje elektryczne",
      ],
      counter: 0,
      typerClass: "",
    };
  },
  mounted() {
    setInterval(() => {
      this.typerClass = "typer-out";

      setTimeout(() => {
        if (this.counter + 1 >= this.texts.length) {
          this.counter = 0;
        } else {
          this.counter++;
        }
        this.typerClass = "typer-in";
      }, 380);
    }, 4000);
  },
});
